.FileManager {
  .header {
    position: fixed;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: calc(100% - 56px);
    background-color: #fafafa;

    .filesInfo {
      box-sizing: border-box;
      width: 100%;
      padding-top: 15px;
      padding-left: 32px;
      padding-right: 32px;
      h2 {
        margin: 0;
        margin-bottom: 19px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        color: #232300;
      }
      .foldersListHeader {
        width: calc(100% - 88px);
        height: 21px;
        margin-bottom: 5px;
        padding-left: 64px;
        padding-right: 20px;
        display: flex;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #7d7d7d;

        span {
          display: block;
        }

        .name {
          width: 980px;
          span {
            position: relative;
            left: -64px;
          }
        }

        .created {
          width: 292px;
          min-width: 80px;
        }

        .modified {
          width: 231px;
          min-width: 80px;
          white-space: nowrap;
        }

        .emptyContainer {
          min-width: 30px;
        }
      }
    }

    .menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      min-height: 69px;
      padding-left: 32px;
      padding-right: 32px;
      border-bottom: 1px solid #d8d8d8;

      .breadCrumbs {
        width: calc(100% - 140px);
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;

        h1 {
          margin-right: 8px !important;
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          line-height: 30px;
          margin: 0;
          color: #656565;
          cursor: pointer;
        }

        .breadCrumbArrow {
          display: block;
          width: 24px;
          height: 24px;
          margin-right: 8px;
          font-size: 0;
          background-image: url(../../assets/images/breadCrumbArrow.svg);
        }

        .crumb {
          margin-right: 8px;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 25px;
          color: #656565;
          cursor: pointer;

          &.lastOne {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 25px;
            color: #969696;
            cursor: auto;
            pointer-events: none;
          }
        }
      }

      .sortMenu {
        position: fixed;
        right: 87px;
        /*     margin-left: auto;
        margin-right: 22px; */
        cursor: pointer;

        span {
          position: relative;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 18px;
          display: flex;
          align-items: center;
          color: #656565;

          i {
            display: block;
            width: 22px;
            height: 22px;
            margin-left: 10px;
            font-size: 0;
            background-image: url(../../assets/images/arrowDown.svg);

            &.asc {
              transform: scale(1, -1);
            }
          }
        }

        .menuContent {
          position: absolute;
          z-index: 1;
          display: none;
          top: 28px;
          right: 11px;
          width: 280px;
          background-color: #ffffff;
          box-shadow: 0px 2px 13px -5px #515151;
          border-radius: 8px;

          &.visible {
            display: block;
          }

          ul {
            margin: 0;
            padding: 0;
            padding-top: 8px;
            padding-bottom: 8px;
            list-style: none;

            li {
              position: relative;
              display: flex;
              align-items: center;
              height: 38px;
              padding-left: 49px;
              font-style: normal;
              font-weight: normal;
              font-size: 15px;
              line-height: 20px;
              display: flex;
              align-items: center;
              color: #656565;

              &.selected {
                &::after {
                  display: block;
                }
              }

              &::after {
                content: '';
                position: absolute;
                left: 12px;
                display: none;
                width: 24px;
                height: 24px;
                background-image: url(../../assets/images/checkMarkIcon.svg);
              }

              &:hover {
                background-color: #f3f3f3;
              }
            }
          }
        }
      }

      .viewModeSwitcher {
        position: fixed;
        right: 33px;
        width: 32px;
        height: 32px;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;

        &.gridOption {
          background-image: url(../../assets/images/gridViewIcon.svg);
        }

        &.listOption {
          background-image: url(../../assets/images/listViewIcon.svg);
        }
      }
    }
  }

  .mainContent {
    padding-top: 118px;
    padding-left: 32px;
    padding-right: 32px;
    &.gridView {
      h2 {
        margin: 0;
        margin-bottom: 15px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        color: #232300;
      }

      .foldersListHeader {
        display: none;
      }

      .foldersContainer {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 27px;

        .folder {
          position: relative;
          display: flex;
          align-items: center;
          width: 271px;
          height: 60px;
          margin-right: 18px;
          margin-bottom: 12px;
          padding-left: 64px;
          padding-right: 20px;
          background-color: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.15);
          box-sizing: border-box;
          border-radius: 8px;
          cursor: pointer;

          h1 {
            margin: 0;
            padding: 0;
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            color: #232300;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .createdAt,
          .updatedAt {
            display: none;
          }

          .folderMenu {
            position: absolute;
            left: 0;
            bottom: 0;
          }

          &::after {
            content: '';
            position: absolute;
            left: 20px;
            display: block;
            width: 24px;
            height: 24px;
            background-image: url(../../assets/images/folderIcon.svg);
          }
        }
      }

      .filesContainer {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        header {
          width: 100%;
          height: 40px;

          h2 {
            margin: 0;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 25px;
            color: #232300;
          }
        }

        .addResearchButton {
          display: flex;
          flex-direction: column;
          align-items: center;
          box-sizing: border-box;
          width: 272px;
          height: 271px;
          margin-right: 18px;
          margin-bottom: 18px;
          padding-top: 74px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          text-align: center;
          letter-spacing: 1px;
          color: #232300;
          background: #fafafa;
          //box-shadow: 0px 1px 11px -6px #515151;
          border-radius: 8px;
          cursor: pointer;

          img {
            margin-bottom: 4px;
          }
        }

        .file {
          box-sizing: border-box;
          width: 272px;
          height: 272px;
          margin-right: 18px;
          margin-bottom: 18px;
          background-image: #ffffff;
          box-shadow: 0px 2px 11px -6px #515151;
          border-radius: 8px;

          video {
            width: 272px;
            height: 212px;
            object-fit: cover;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            box-shadow: 0px 2px 11px -6px #515151;

            &:focus {
              outline: none;
            }
          }

          footer {
            position: relative;
            top: -4px;
            display: flex;
            align-items: center;
            height: 59px;
            padding-left: 12px;
            padding-right: 12px;

            i {
              display: block;
              min-width: 24px;
              height: 24px;
              margin-right: 13px;
              font-size: 0;
              background-image: url(../../assets/images/videoIcon.svg);
            }

            h1.title {
              margin: 0;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 19px;
              color: #232300;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .dropDownContainer {
              margin-left: auto;
            }
          }
        }
      }
    }

    &.listView {
      .foldersContainer {
        display: block;

        .folder {
          position: relative;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          width: 100%;
          height: 60px;
          margin-bottom: 6px;
          padding-left: 64px;
          padding-right: 20px;
          background-color: #ffffff;
          border-bottom: 1px solid rgba(0, 0, 0, 0.15);
          box-shadow: 0px -1px 4px -3px rgba(0, 0, 0, 0.5);
          cursor: pointer;

          &.addNewResearch {
            &::after {
              background-image: url(../../assets/images/addResearchIcon.svg);
            }
          }

          h1 {
            width: 980px;
            margin: 0;
            padding: 0;
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            color: #232300;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .createdAt,
          .updatedAt {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: #7d7d7d;
          }

          .createdAt {
            position: relative;
            width: 292px;
            min-width: 80px;
          }

          .updatedAt {
            position: relative;
            width: 231px;
            min-width: 80px;
          }

          .folderMenu {
            margin-left: auto;
          }

          &::after {
            content: '';
            position: absolute;
            left: 20px;
            display: block;
            width: 30px;
            height: 30px;
            background-image: url(../../assets/images/folderIcon.svg);
            background-size: contain;
          }
        }
      }

      .filesContainer {
        .file {
          box-sizing: border-box;
          width: 100%;
          height: 60px;
          margin-bottom: 7px;
          padding-left: 64px;
          padding-right: 20px;
          background: #ffffff;
          border-bottom: 1px solid rgba(0, 0, 0, 0.15);
          box-shadow: 0px -1px 4px -3px rgba(0, 0, 0, 0.5);
          cursor: pointer;
          .preview {
            display: none;
          }
          footer {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 100%;

            h1.title {
              width: 980px;
              margin: 0;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 19px;
              letter-spacing: 0.318182px;
              color: #232300;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .createdAt,
            .updatedAt {
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 19px;
              color: #7d7d7d;
            }

            .createdAt {
              width: 292px;
              min-width: 80px;
            }

            .updatedAt {
              width: 231px;
              min-width: 80px;
            }

            i {
              position: absolute;
              left: -45px;
              display: block;
              width: 30px;
              height: 30px;
              margin-right: 13px;
              font-size: 0;
              background-image: url(../../assets/images/videoIcon.svg);
              background-size: contain;
            }

            .dropDownContainer {
              margin-left: auto;
            }
          }
        }
      }
    }
  }
}
