.PieChartComponent {
  position: relative;
  top: 20px;
  width: 147px;
  height: 74px;
  h1 {
    margin: 0;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.99);
  }

  .dataContainer {
    display: flex;
    align-items: center;
    .chartContainer {
      margin-right: 10px;
      .chart {
        position: relative;
        width: 50px;
        height: 50px;

        .innerCircle {
          position: absolute;
          top: calc(50% - 12px);
          left: calc(50% - 12px);
          width: 24px;
          height: 24px;
          background-color: #fafafa;
          border-radius: 50%;
        }
      }
    }
    .percentages {
      ul {
        position: relative;
        top: -1px;
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          position: relative;
          padding-left: 10px;
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          line-height: 14px;
          color: #7d7d7d;
          white-space: nowrap;

          &.women {
            &:after {
              background-color: #ff9800;
            }
          }

          &.other {
            &:after {
              background-color: #339de0;
            }
          }

          &.men {
            &:after {
              background-color: #2ecc71;
            }
          }

          &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 5px;
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
