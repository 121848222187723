.PercentageGraphs {
  .radialCharts {
    display: flex;
    margin-bottom: 27px;
    .chart {
      position: relative;
      width: 60px;
      height: 60px;
      margin-right: 25px;
    }
  }

  .pieCharts {
    display: flex;
    .firstPieChart {
      margin-right: 42px;
    }
  }
}
