.EditFile {
  position: relative;
  padding-top: 94px;

  .zoomBar {
    position: absolute;
    top: 385px;
    right: 36px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 349px;
    height: 20px;
    padding-left: 8px;
    background: #ffffff;
    border: 1px solid #d9d9d9;

    input {
      box-sizing: border-box;
      width: 227px;
      height: 4px;
      margin: 0;
      margin-right: 8px;
      appearance: none;
      background: #d8d8d8;
      border-radius: 4.5px;

      &:focus {
        outline: none;
      }

      &::-webkit-slider-thumb {
        appearance: none;
        box-sizing: border-box;
        width: 12px;
        height: 12px;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        cursor: pointer;
      }

      &::-moz-range-thumb {
        position: absolute;
        z-index: 2;
        appearance: none;
        box-sizing: border-box;
        width: 12px;
        height: 12px;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        cursor: pointer;
      }
    }

    i {
      display: block;
      min-width: 16px;
      height: 16px;
      font-size: 0;
      cursor: pointer;

      &.decreaseZoom {
        position: relative;
        margin-right: 8px;

        &::after {
          content: '';
          position: absolute;
          left: calc(50% - 4.5px);
          top: calc(50% - 1px);
          display: block;
          width: 9px;
          height: 1px;
          background-color: #656565;
        }
      }

      &.increaseZoom {
        margin-right: 30px;
        background-image: url(../../../assets/images/zoomButton.svg);
      }
    }

    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #656565;
    }
  }

  .firstRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 35px;
    padding-right: 35px;
    margin-bottom: 50px;
    .videoPlayer {
      position: relative;
      margin-right: 50px;
    }

    .filters {
      margin-right: 50px;
    }

    .fullScreenButton {
      position: absolute;
      z-index: 1;
      box-sizing: border-box;
      width: 50px;
      height: 45px;
    }

    video {
      width: 352px;
      height: 262px;
      object-fit: cover;
      background-color: black;
      &:focus {
        outline: none !important;
      }
    }

    .rightMenu {
      position: absolute;
      top: 314px;
      right: 36px;
    }

    .leftMenu {
      position: absolute;
      top: 314px;
      left: 36px;
    }
  }

  .secondRow {
    box-sizing: border-box;
    padding-left: 35px;
    padding-right: 35px;
  }
}
