.Filters {
  .gaussFilters {
    display: flex;
    .filter {
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      width: 90px;
      height: 110px;
      margin-right: 10px;
      padding-top: 3px;
      cursor: pointer;

      &.active {
        background: #ffffff;
        box-shadow: 0px 3px 14px -7px #000000;
        border-radius: 6px;
      }

      &.gray {
        .preview {
          background-image: url(../../../../assets/images/gaussGray.png);
        }
      }

      &.origin {
        .preview {
          background-image: url(../../../../assets/images/origin.png);
        }
      }

      .preview {
        width: 85px;
        height: 85px;
        background-image: url(../../../../assets/images/gauss.png);
        background-size: cover;
        border-radius: 4px;
      }

      .title {
        display: flex;
        align-items: center;
        height: 25px;
        h1 {
          margin: 0;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 18px;
          color: #232300;
        }
      }
    }
  }
}
