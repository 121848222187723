.SideBar {
  position: fixed;
  z-index: 3;
  top: 64px;
  bottom: 0;
  width: 56px;
  padding-top: 7px;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12), 0px 0px 8px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  transition: width 0.05s ease-out;

  &.expanded {
    width: 235px;

    .expandIcon {
      transform: scaleX(-1);
    }
  }

  .expandIcon {
    display: block;
    width: 26px;
    height: 26px;
    margin-left: 14px;
    margin-bottom: 15px;
    font-size: 0;
    background-image: url(../../assets/images/expandSideBarIcon.svg);
    cursor: pointer;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      display: flex;
      align-items: center;
      height: 38px;
      padding-left: 64px;
      margin-bottom: 8px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #232323;
      white-space: nowrap;
      cursor: pointer;

      &.active {
        background-color: rgba(216, 216, 216, 0.3);
      }

      &::after {
        content: '';
        position: absolute;
        left: 17px;
        display: block;
        width: 22px;
        height: 21px;
      }

      &:hover {
        background-color: rgba(216, 216, 216, 0.3);
      }

      &.addResearch::after {
        background-image: url(../../assets/images/addResearchIcon.svg);
      }

      &.addDirectory::after {
        background-image: url(../../assets/images/addDirectoryIcon.svg);
      }

      &.shared::after {
        background-image: url(../../assets/images/sharedIcon.svg);
      }

      &.myLab::after {
        width: 22px;
        height: 21px;
        background-image: url(../../assets/images/folderIconGray.svg);
        background-size: contain;
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 12px;
    display: flex;
    align-items: center;
    padding-left: 14px;
    cursor: pointer;

    i {
      display: block;
      width: 22px;
      height: 22px;
      margin-right: 20px;
      font-size: 0;
      background-image: url(../../assets/images/helpIcon.svg);
      background-size: contain;
      background-position: center center;
    }

    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #232323;
    }
  }
}
