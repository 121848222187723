html,
#root {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  background-color: #fafafa;
}

* {
  //border: 1px solid red;
}
