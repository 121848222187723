.MainCharts {
  position: relative;
  padding-top: 10px;
  padding-left: 11px;
  padding-right: 11px;
  padding-bottom: 23px;
  background-color: #ffffff;
  box-shadow: 0px 3px 14px -6px rgba(0, 0, 0, 0.5);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  &::after {
    content: '';
    position: relative;
    bottom: -95px;
    display: block;
    width: 10px;
    height: 10px;
  }

  .scrollBarContainer {
    position: absolute;
    left: 0;
    bottom: -30px;
    width: 100%;
  }
}
