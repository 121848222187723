.FormField {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 21px;
  span.label {
    font-size: 14px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 8px;
  }
  span.errorMessage {
    display: none;
    font-size: 12px;
    line-height: 16px;
    color: #f44336;
  }
  span.successMessage {
    display: none;
    font-size: 12px;
    line-height: 16px;
    color: #2ecc71;
  }
  .forgotPasswordLink {
    display: none;
    position: absolute;
    top: 2px;
    right: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #339de0;
    text-decoration: none;
  }
  input {
    box-sizing: border-box;
    height: 48px;
    padding-top: 14px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 14px;
    font-size: 14px;
    line-height: 19px;
    color: #232300;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    &:focus {
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0.35);
      border-radius: 4px;
    }
    &:disabled {
      background: #f2f2f2;
      border: 1px solid rgba(0, 0, 0, 0.15);
      box-sizing: border-box;
      border-radius: 4px;
    }
    &:read-only {
      mix-blend-mode: normal;
      opacity: 0.6;
      color: #232300;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }
  }

  &.isInvalid {
    input {
      border: 1px solid #f44336;
    }
    span.errorMessage {
      display: block;
    }
  }

  &.isValid {
    input {
      border: 1px solid #2ecc71;
    }
    span.successMessage {
      display: block;
    }
  }

  &.forgotPassword {
    .forgotPasswordLink {
      display: block;
    }
  }
}
