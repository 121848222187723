.PopupMessage {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;

  &.show {
    display: block;
  }

  .popupOverlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
  span {
    position: absolute;
    bottom: 0;
    display: block;
    padding: 10px;
    color: white;
    font-size: 16px;
    background-color: #323232;
    border-radius: 5px;
  }
}
