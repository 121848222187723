.overlay {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}

.HighlightMenu {
  position: fixed;
  //top: 600px;
  left: 250px;
  z-index: 4;
  display: none;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 136px;
  height: 172px;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 0;
  background-color: #ffffff;
  box-shadow: 0px -3px 14px -7px #000000;
  border-radius: 6px;

  &.noComment {
    height: 100px;

    &.top {
      &::after {
        top: 95px;
      }
    }

    .deleteButtonContainer {
      margin-bottom: 18px;

      &::after {
        content: '';
        position: absolute;
        width: 104px;
        height: 1px;
        background-color: #d9d9d9;
        bottom: -8.5px;
      }
    }
  }

  &.show {
    display: flex;
  }

  &.top {
    &::after {
      top: 167px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: -4.5px;
    left: 62.5px;
    display: block;
    width: 9px;
    height: 9px;
    background-color: white;
    transform: rotate(45deg);
  }

  .deleteButtonContainer {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 9px;

    button {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      letter-spacing: 2px;
      color: #f44336;
      text-transform: uppercase;
      border: none;
      background-color: white;
      cursor: pointer;
    }
  }

  textarea {
    resize: none;
    width: 100%;
    height: 70px;
    margin-bottom: 10px;
    padding: 7px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #232300;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    border-radius: 6px;

    &:focus {
      outline: none;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    width: 104px;
    margin: 0;
    margin-bottom: 13px;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      display: block;
      width: 14px;
      height: 14px;
      margin-right: 16px;
      margin-bottom: 16px;
      border-radius: 50%;
      cursor: pointer;

      &:nth-child(4n + 4) {
        margin-right: 0;
      }

      &.active {
        &::after {
          content: '';
          position: absolute;
          left: -3px;
          top: -3px;
          display: block;
          box-sizing: border-box;
          width: 20px;
          height: 20px;
          border: 1px solid red;
          border-radius: 50%;
        }
      }

      &.green {
        background-color: #2ecc71;

        &::after {
          border-color: #2ecc71;
        }
      }

      &.orange {
        background-color: #ff9800;

        &::after {
          border-color: #ff9800;
        }
      }

      &.red {
        background-color: #f44336;

        &::after {
          border-color: #f44336;
        }
      }

      &.gray {
        background-color: #9e9e9e;

        &::after {
          border-color: #9e9e9e;
        }
      }

      &.purple {
        background-color: #9c27b0;

        &::after {
          border-color: #9c27b0;
        }
      }

      &.blue {
        background-color: #339de0;

        &::after {
          border-color: #339de0;
        }
      }

      &.yellow {
        background-color: #f3db05;

        &::after {
          border-color: #f3db05;
        }
      }

      &.pink {
        background-color: #cc2e8a;

        &::after {
          border-color: #cc2e8a;
        }
      }
    }
  }
}
