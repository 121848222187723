.PresentationPage {
  .presentationHeader {
    position: fixed;
    z-index: 1;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 66px;
    padding-left: 25px;
    background-color: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);

    .presentationLogo {
      width: 198px;
      height: 44px;
      background-image: url(../../assets/images/logo.svg);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}
