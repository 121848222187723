.Highlight {
  position: relative;
  box-sizing: border-box;
  border-bottom: 1px solid;
  height: 100%;

  .threeDots {
    position: absolute;
    bottom: -4px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 24px;
    height: 8px;
    background-color: #ffffff;
    border: 1px solid;
    box-sizing: border-box;
    border-radius: 4px;

    .dotsContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 14px;
      height: 3px;

      div {
        box-sizing: border-box;
        min-width: 4px;
        max-width: 4px;
        min-height: 4px;
        max-height: 4px;
        border-radius: 50%;
      }
    }
  }
}
