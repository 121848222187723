.ShareEntityModal {
  min-width: 680px;
  .header {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #656565;
  }

  .shareLingContainer {
    display: flex;
    align-items: center;
    height: 56px;
    input {
      box-sizing: border-box;
      width: 460px;
    }
    button {
      position: relative;
      top: -7px;
      box-sizing: border-box;
      min-width: 170px;
      height: 48px;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.5px;
      color: #339de0;
      background-color: transparent;
      border: 1px solid #339de0;
      border-radius: 6px;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
  }

  .emailForm {
    position: relative;
    display: flex;
    align-items: center;
    input {
      width: 506px;
      padding-right: 47px;
    }
    button {
      position: relative;
      top: 2px;
    }
    .addEmail {
      position: absolute;
      top: 37px;
      left: 467px;
      box-sizing: border-box;
      width: 30px;
      min-width: 30px;
      height: 30px;
      margin-left: auto;
      font: 22px Arial;
      color: darkgray;
      background-position: center;
      border: none;
      border-radius: 50%;
      cursor: pointer;

      &:focus {
        outline: none;
      }
      &::-moz-focus-inner {
        border: 0;
        padding: 0;
      }
    }
  }

  .emails {
    display: flex;
    flex-wrap: wrap;
    .email {
      position: relative;
      display: flex;
      align-items: center;
      margin-right: 5px;
      margin-bottom: 5px;
      padding: 2px;
      padding-left: 6px;
      padding-right: 20px;
      font-size: 14px;
      border: 1px solid lightgray;
      border-radius: 15px;

      i {
        position: absolute;
        right: 6px;
        cursor: pointer;
      }
    }
  }
}
