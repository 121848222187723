.container {
  position: relative;
  .threadMark {
    position: absolute;
    z-index: 0;
    top: -3px;
    left: -4px;
    width: 10px;
    height: 10px;
    background-color: #ff9800;
    border-radius: 50%;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: 5px;
      left: 3.5px;
      display: block;
      width: 3px;
      height: 64px;
      background: rgba(255, 152, 0, 0.7);
    }
  }
  .verticalLine {
    display: block;
    position: absolute !important;
    z-index: 2;
    left: 6px;
    top: 19px;
    z-index: 1;
    width: 2px;
    height: 450px;
    background-color: #746f60;
  }
}
.SceneLine {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-height: 64px;
  background-color: black;
  border-radius: 4px 4px 0px 0px;
  overflow-x: hidden !important;
  overflow-y: hidden;

  .timeline {
    display: flex;
    //justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    min-height: 15px;
    padding-left: 4px;
    padding-right: 4px;
    background: #232323;
    border-radius: 4px 4px 0px 0px;

    .line {
      position: relative;
      display: flex;
      align-items: center;
      min-width: 1px;
      max-width: 1px;
      height: 10px;
      margin-right: 19px;
      background-color: rgba(255, 255, 255, 0.3);
      user-select: none;
      //pointer-events: none;

      .timeStamp {
        margin-left: 7px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: 0.272727px;
        color: rgba(255, 255, 255, 0.75);
        user-select: none;
      }

      &.highlight {
        background-color: rgba(255, 255, 255, 0.8);
      }
    }
  }
  .frames {
    display: flex;
    box-sizing: border-box;
    height: 42px;
    min-width: 100%;
    padding-top: 6px;
    background-color: #000000;

    .frame {
      box-sizing: border-box;
      min-width: 80px;
      background-image: url(https://picsum.photos/200/300);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .footer {
    box-sizing: border-box;
    height: 6px;
    border-top: 1px solid #1e3553;
  }

  .cursor {
    position: absolute;
    z-index: 0;
    top: 2px;
    left: 0;
    width: 14px;
    height: 19px;
    background-image: url(../../../../assets/images/videoCursor.svg);
    cursor: pointer;
    transition: left 0.6s ease-out;
  }
}
