.FileUploadModal {
  max-width: 680px;
  padding: 0;

  .modalBody {
    padding: 20px;
    .uploader {
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      height: 380px;
      padding-top: 47px;
      background-color: #fafafa;
      border: 2px dashed #93e2b5;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      img {
        margin-bottom: 29px;
      }

      p.firstLine {
        margin: 0;
        margin-bottom: 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 34px;
        line-height: 46px;
        color: #7d7d7d;
      }

      p.secondLine {
        margin: 0;
        margin-bottom: 29px;
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 30px;
        color: #7d7d7d;

        .logo {
          font-weight: bold;
        }
      }

      .browseButton {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 228px;
        height: 52px;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 30px;
        letter-spacing: 0.5px;
        color: #339de0;
        border: 1px solid #339de0;
        box-sizing: border-box;
        border-radius: 6px;
      }
    }
  }
}
