.VideoPlayerModal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;

  &.show {
    display: block;
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
  }
  .videoContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .playerContainer {
      position: relative;
      z-index: 2;

      .fullScreenButton {
        position: absolute;
        top: 100px;
        left: 100px;
        z-index: 2;

        &.firefox {
          top: 704px;
          left: 960px;
          width: 35px;
          height: 40px;
        }

        &.chrome {
          top: 675px;
          left: 903px;
          width: 50px;
          height: 45px;
        }
      }

      .chartsContainer {
        position: absolute;
        top: 0;
        left: 0;
      }

      video {
        width: 1000px;
        height: 744px;
        background-color: black;

        &:focus {
          outline: none;
        }
      }

      .filtersContainer {
        position: absolute;
        top: 0;
        right: 0;
        div {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

@media only screen and (max-height: 768px) {
  .VideoPlayerModal {
    .videoContainer {
      .playerContainer {
        .fullScreenButton {
          &.firefox {
            top: 560px;
            left: 758px;
          }

          &.chrome {
            top: 529px;
            left: 703px;
          }
        }
      }
      video {
        width: 800px !important;
        height: 600px !important;
      }
    }
  }
}
