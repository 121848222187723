.MoveFileModal {
  .header {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 71px;
    padding-top: 0;
    padding-bottom: 0;

    button {
      position: relative;
      top: -4px;
    }

    .headerContent {
      position: absolute;
      top: 20px;
      left: 20px;
      display: flex;
      width: 460px;
      height: 51px;
      border-bottom: 1px solid #e6e6e6;
      i {
        display: block;
        min-width: 24px;
        height: 24px;
        margin-right: 17px;
        font-size: 0;
        background-image: url(../../assets/images/arrowLeft.svg);
        cursor: pointer;
      }

      span.title {
        position: relative;
        top: -5px;
        width: calc(100% - 70px);
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        color: #656565;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .body {
    box-sizing: border-box;
    height: 222px;
    overflow-y: auto;
    padding: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    .folder,
    .file {
      position: relative;
      display: flex;
      align-items: center;
      width: calc(100% - 64px);
      height: 48px;
      padding-left: 64px;
      cursor: pointer;

      &.moveUp {
        box-sizing: border-box;
        width: 100%;
        padding-left: 20px;
      }

      .openFolderIcon {
        display: none;
        width: 26px;
        height: 26px;
        margin-left: auto;
        margin-right: 20px;
        font-size: 0;
        background-image: url(../../assets/images/arrowRightButton.svg);
      }

      &:not(.file):hover {
        background-color: #f3f3f3;
      }

      &:not(.moveUp) {
        &:hover {
          .openFolderIcon {
            display: block;
          }
        }
      }

      &:not(.moveUp):not(.file)::after {
        content: '';
        position: absolute;
        left: 20px;
        display: block;
        width: 30px;
        height: 30px;
        background-image: url(../../assets/images/folderIcon.svg);
        background-size: contain;
      }

      h1 {
        width: calc(100% - 64px);
        margin: 0;
        padding: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.318182px;
        color: #232300;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .file {
      cursor: auto;
      user-select: none;
      &::after {
        content: '';
        position: absolute;
        left: 20px;
        display: block;
        width: 30px;
        height: 30px;
        background-image: url(../../assets/images/videoIcon.svg);
        background-size: contain;
      }
    }
  }

  .footer {
    box-sizing: border-box;
    width: 460px;
    height: 108px;
    margin: auto;
    padding: 0;
    border-top: 1px solid #e6e6e6;
  }
}
