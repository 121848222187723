.EmptyHeader {
  position: fixed;
  top: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  height: 65px;
  padding-left: 11px;
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  .logo {
    width: 152px;
    height: 26px;
  }
}
