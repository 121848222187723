/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  top: -4px;
  padding-left: 35px;
  margin-bottom: 40px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .errorMessage {
    position: relative;
    left: -36px;
    display: none;
    font-size: 12px;
    line-height: 16px;
    color: #f44336;
  }

  &.invalid {
    .checkmark {
      border: 1px solid #f44336;
    }
    .errorMessage {
      display: block;
    }
  }
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  box-sizing: border-box;
  position: absolute;
  top: 0.5px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 2px;
  &:focus {
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.35);
  }
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6px;
  top: 0;
  width: 5px;
  height: 12px;
  border: solid black;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.label {
  font-size: 14px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.65);
}
