.BarChart {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 73px;
  margin-bottom: 13px;
  overflow-x: hidden;
  overflow-y: hidden;

  .chartHeader {
    height: 19px;
    margin-bottom: 2px;
    position: sticky;
    left: 0;
    h1 {
      margin: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.3px;
      color: #232323;
    }
  }

  .chartMain {
    display: flex;
    &.selectionActive {
      cursor: text;
    }
    .axis {
      display: flex;
      flex-direction: column;

      span {
        margin-bottom: 4px;
        font-size: 4px;
      }
    }

    .chart {
      position: relative;
      top: 2px;
      width: 100%;
      height: 46px;
      box-sizing: border-box;
      border-top: 1px solid #d9d9d9;
      border-bottom: 1px solid #d9d9d9;
      overflow-y: hidden;
      overflow-x: auto;

      .background {
        position: absolute;
        width: 100%;
        height: 100%;
        padding-top: 8px;

        div.grayLine {
          width: 100%;
          height: 1px;
          margin-bottom: 8px;
          background-color: #d9d9d9;
        }

        .redLine {
          position: absolute;
          top: 0;
          width: 100%;
          height: 9px;
          background: rgba(244, 67, 54, 0.3);
        }
      }
    }
  }

  .selection {
    position: absolute;
    top: 23px;
    left: -250px;
    width: 0;
    box-sizing: border-box;
    height: 46px;
    background-color: rgba(255, 152, 0, 0.3);

    &.selected {
      border-bottom: 1px solid #ff9800;
      .highlightMenu {
        display: flex;
      }
    }
  }

  .highlights {
    position: absolute;
    top: 24px;
    width: 100%;
    height: 46px;
    .highlight {
      position: absolute;
      z-index: 1;
      height: 45px;
    }
  }
}
