.PixelatedBarChart {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 53px;
  margin-bottom: 13px;
  overflow-y: hidden;
  overflow-x: hidden;

  .pixelatedBarChartHeader {
    height: 19px;
    position: sticky;
    left: 0;

    h1 {
      margin: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      display: flex;
      align-items: center;
      letter-spacing: 0.3px;
      color: #232323;
    }
  }

  .pixelatedBarChartMain {
    svg {
      background-color: #18532d;
    }
  }

  .selection {
    position: absolute;
    top: 20px;
    left: -250px;
    width: 0;
    box-sizing: border-box;
    height: 29px;
    background-color: rgba(255, 152, 0, 0.3);

    &.selected {
      border-bottom: 1px solid #ff9800;
      .highlightMenu {
        display: flex;
      }
    }
  }

  .highlights {
    position: absolute;
    top: 23px;
    width: 100%;
    height: 29px;
    &.selectionActive {
      cursor: text;
    }
    .highlight {
      height: 26px;
      position: absolute;
      cursor: auto;
    }
  }
}
