.file.list {
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  margin-bottom: 7px;
  padding-left: 64px;
  padding-right: 20px;
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px -1px 4px -3px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  .preview {
    display: none;
  }
  footer {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

    h1.title {
      width: 980px;
      margin: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.318182px;
      color: #232300;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .createdAt,
    .updatedAt {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #7d7d7d;
    }

    .createdAt {
      width: 292px;
      min-width: 80px;
    }

    .updatedAt {
      width: 231px;
      min-width: 80px;
    }

    i {
      position: absolute;
      left: -45px;
      display: block;
      width: 30px;
      height: 30px;
      margin-right: 13px;
      font-size: 0;
      background-image: url(../../../assets/images/videoIcon.svg);
      background-size: contain;
    }

    .dropDownContainer {
      margin-left: auto;
    }
  }
}

.file.grid {
  box-sizing: border-box;
  width: 272px;
  height: 272px;
  margin-right: 18px;
  margin-bottom: 18px;
  background-image: #ffffff;
  box-shadow: 0px 2px 11px -6px #515151;
  border-radius: 8px;
  cursor: pointer;

  .videoContainer {
    position: relative;
    z-index: 101;
    .spinner {
      position: absolute;
      top: 0;
      bottom: 5px;
      z-index: 111;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    .errorContainer {
      position: absolute;
      top: 0;
      bottom: 5px;
      z-index: 111;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.65);
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      .error {
        display: flex;
        flex-direction: column;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #ff4c3f;
      }
    }
    video {
      position: relative;
      z-index: 2;
      width: 272px;
      height: 212px;
      object-fit: cover;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      box-shadow: 0px 2px 11px -6px #515151;

      &:focus {
        outline: none;
      }
    }
  }

  footer {
    position: relative;
    top: -4px;
    display: flex;
    align-items: center;
    height: 59px;
    padding-left: 12px;
    padding-right: 12px;

    i {
      display: block;
      min-width: 24px;
      height: 24px;
      margin-right: 13px;
      font-size: 0;
      background-image: url(../../../assets/images/videoIcon.svg);
    }

    h1.title {
      margin: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #232300;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .dropDownContainer {
      margin-left: auto;
    }
  }
}
