.tooltip {
  .tooltipContent {
    position: relative;
    padding-left: 22px;
    &:after {
      content: '';
      position: absolute;
      top: 4px;
      left: 0;
      display: block;
      width: 12px;
      height: 12px;
      background-color: #2ecc71;
      border-radius: 50%;
    }
  }
}

.CircleChart {
  position: relative;
  width: 61px;
  height: 79px;

  h1 {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #656565;
    text-align: center;
  }
  .percentage {
    position: absolute;
    top: calc(50% - 21px);
    left: calc(50% - 10px);
    display: block;
    width: 19px;
    height: 22px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #232300;
    user-select: none;
  }
}

.progressPieChart {
  position: absolute !important;
  top: -70px;
  left: -70px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #fafafa;
  position: relative;
  transform: scale(-0.3, 0.3);
}
.progressPieChart.gt50 {
  background-color: #2ecc71;
}

.ppcProgress {
  content: '';
  position: absolute;
  border-radius: 50%;
  left: calc(50% - 100px);
  top: calc(50% - 100px);
  width: 200px;
  height: 200px;
  clip: rect(0, 200px, 200px, 100px);
}
.ppcProgress .ppcProgressFill {
  content: '';
  position: absolute;
  border-radius: 50%;
  left: calc(50% - 100px);
  top: calc(50% - 100px);
  width: 200px;
  height: 200px;
  clip: rect(0, 100px, 200px, 0);
  background: #2ecc71;
  transform: rotate(60deg);
}
.gt50 .ppcProgress {
  transform: scale(1.02);
  clip: rect(0, 100px, 200px, 0);
}
.gt50 .ppcProgress .ppcProgressFill {
  clip: rect(0, 200px, 200px, 100px);
  background: #fafafa;
}

.ppcPercents {
  content: '';
  position: absolute;
  border-radius: 50%;
  left: calc(50% - 173.91304px / 2);
  top: calc(50% - 173.91304px / 2);
  width: 173.91304px;
  height: 173.91304px;
  background: #fafafa;
  text-align: center;
  display: table;
  transform: scale(0.97);
}
.ppcPercents span {
  display: block;
  font-size: 2.6em;
  font-weight: bold;
  color: #81ce97;
}

.pccPercentsWrapper {
  display: table-cell;
  vertical-align: middle;
}

.grayCircle {
  position: relative;
  top: -5px;
  left: -6px;
  z-index: -99;
  width: 100%;
  height: 100%;
  border: 7px solid #d8d8d8;
  border-radius: 50%;
  transform: scale(0.9, 0.88);
}

.gt50 .grayCircle {
  display: none;
}

.gt50 .invertedGrayCircle {
  position: absolute;
  top: -4px;
  transform: scale(0.95, 0.9);
  display: block;
}

.invertedGrayCircle {
  display: none;
  width: 100%;
  height: 100%;
  border: 7px solid #d8d8d8;
  border-radius: 50%;
}
