.Layout {
  .content {
    padding-top: 64px;
    padding-left: 56px;
    transition: padding-left 0.05s ease-out;

    &.hidden {
      padding-top: 0;
      padding-left: 0;
    }
  }

  &.wideSideBar {
    .content {
      padding-left: 235px;
    }
  }
}
