.ChangePasswordPage {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .logo {
    width: 355px;
    height: 60px;
    margin-bottom: 15px;
  }
  .formContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    padding-top: 40px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 40px;
    background-color: #ffffff;
    box-shadow: 0px 36px 94px rgba(0, 0, 0, 0.0242336),
      0px 8.04107px 20.9961px rgba(0, 0, 0, 0.0357664),
      0px 2.39404px 6.2511px rgba(0, 0, 0, 0.06);
    border-radius: 7px;
    h2 {
      width: 100%;
      margin: 0;
      margin-bottom: 22px;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
      color: #232300;
    }
    .registrationForm {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 400px;
    }
  }
}
