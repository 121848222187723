.Thread {
  position: relative;
  margin-bottom: 26px;
  padding-top: 19px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 8px;

  &.highlight {
    background-color: #f0f0f0;
  }

  .timeStamp {
    position: absolute;
    top: -14px;
    right: 21px;
    display: block;
    padding-left: 7px;
    padding-right: 7px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #656565;
    background-color: white;
    border-radius: 7px;
  }

  .addCommentBox {
    position: relative;
    margin-top: 24px;

    .sendComment {
      position: absolute;
      top: 12px;
      right: 8px;
      display: block;
      width: 24px;
      height: 24px;
      font-size: 0;
      background-image: url(../../../../../assets/images/sendComment.svg);
      cursor: pointer;
    }

    textarea {
      box-sizing: border-box;
      width: 100%;
      height: 48px;
      padding-left: 16px;
      padding-top: 14px;
      padding-right: 32px;
      resize: none;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;

      color: #232300;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 4px;

      &::placeholder {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: rgba(35, 35, 0, 0.5);
      }

      &:focus {
        outline: none;
      }
    }
  }
}
