.dropdownToggle {
  box-sizing: border-box;
  width: 30px;
  min-width: 30px;
  height: 30px;
  margin-left: auto;
  background-image: url(../../../../../../../assets/images/threeDotsIcon.svg);
  background-position: center;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: #f3f3f3;
  }
}

.dropDownMenu {
  z-index: 1;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #ffffff;
  box-shadow: 0px 2px 13px -5px #515151;
  border-radius: 6px;

  ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    a {
      height: 38px;
      padding-left: 20px;
      padding-right: 20px;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #656565;
      text-decoration: none;

      &:hover {
        background-color: #f3f3f3;
      }

      span,
      a.download {
        position: relative;
        height: auto;
        padding: 0;
        &::after {
          content: '';
          position: absolute;
          top: -2px;
          left: -37px;
          display: block;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
