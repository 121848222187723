.Comment {
  margin-bottom: 16px;
  .commentHeader {
    display: flex;
    margin-bottom: 8px;

    .avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 38px;
      height: 38px;
      margin-right: 14px;
      background: rgba(52, 193, 101, 0.1);
      border: 1px solid #34c266;
      box-sizing: border-box;
      border-radius: 50%;
      span {
        position: relative;
        top: -1px;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        color: #34c266;
        vertical-align: middle;
        text-transform: uppercase;
      }
    }

    .details {
      h1 {
        margin: 0;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        color: #232300;
      }

      .date {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: rgba(35, 35, 0, 0.4);
      }
    }

    .commentMenu {
      margin-left: auto;
    }
  }

  .commentMain {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.65);

    p {
      margin: 0;
    }
  }
  .addCommentBox {
    position: relative;
    margin-top: 24px;

    .sendComment {
      position: absolute;
      top: 12px;
      right: 8px;
      display: block;
      width: 24px;
      height: 24px;
      font-size: 0;
      background-image: url(../../../../../../assets/images/sendComment.svg);
      cursor: pointer;
    }

    textarea {
      box-sizing: border-box;
      width: 100%;
      height: 48px;
      padding-left: 16px;
      padding-top: 14px;
      padding-right: 32px;
      resize: none;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;

      color: #232300;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 4px;

      &::placeholder {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: rgba(35, 35, 0, 0.5);
      }

      &:focus {
        outline: none;
      }
    }
  }
}
