.Charts {
  box-sizing: border-box;
  width: 316px;
  height: 163px;
  padding: 7px;
  padding-left: 10px;
  padding-right: 10px;
  background: rgba(255, 255, 255, 0.85);
  border-radius: 0px 0px 8px 0px;
  border: 1px solid transparent;

  .chart {
    h1 {
      margin: 0;
      margin-bottom: 2px;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 15px;
      color: #212100;
    }
    div {
      width: 100%;
      height: 10px;
      margin-bottom: 7px;
      background-color: #339de0;
      border-radius: 5px;
    }
  }

  .axis {
    position: relative;
    top: -5px;
    span {
      margin-right: 15.5px;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 13px;
      color: #232300;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
