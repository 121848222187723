.ForgotPasswordPage {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .passwordFormContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 500px;
    padding-top: 40px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 40px;
    background: #ffffff;
    box-shadow: 0px 36px 94px rgba(0, 0, 0, 0.0242336),
      0px 8.04107px 20.9961px rgba(0, 0, 0, 0.0357664),
      0px 2.39404px 6.2511px rgba(0, 0, 0, 0.06);
    border-radius: 7px;
    .logo {
      margin-bottom: 15px;
    }
    p {
      margin: 0;
      &.heading {
        margin-bottom: 16px;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
        color: #232300;
      }
      &.explanation {
        margin-bottom: 22px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: #7d7d7d;
      }
    }

    .passwordForm {
      display: flex;
      flex-direction: column;
      align-items: center;

      .goBack {
        margin-top: 22px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.409091px;
        color: #339de0;
        text-decoration: none;
      }
    }
  }
  .successMessage {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    padding-top: 40px;
    padding-left: 72px;
    padding-right: 72px;
    padding-bottom: 40px;
    background: #ffffff;
    box-shadow: 0px 36px 94px rgba(0, 0, 0, 0.0242336),
      0px 8.04107px 20.9961px rgba(0, 0, 0, 0.0357664),
      0px 2.39404px 6.2511px rgba(0, 0, 0, 0.06);
    border-radius: 7px;
    .logo {
      margin-bottom: 15px;
    }
    p {
      margin: 0;
      margin-bottom: 31px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #7d7d7d;
    }
    .sign {
      margin-bottom: 21px;
    }
    .homeLink {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      display: flex;
      align-items: center;
      letter-spacing: 0.409091px;
      color: #339de0;
      text-decoration: none;
    }
  }
}
