.FileDetails {
  position: fixed;
  z-index: 4;
  top: 133px;
  right: -328px;
  bottom: 0;
  box-sizing: border-box;
  width: 328px;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12), 0px 8px 8px rgba(0, 0, 0, 0.12);
  transition: right 0.05s ease-out;

  &.show {
    right: 0;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    h1 {
      position: relative;
      margin: 0;
      width: 230px;
      padding-left: 34px;
      font-weight: 600;
      font-size: 22px;
      color: #232300;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &::after {
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        display: block;
        width: 24px;
        height: 24px;
        background-image: url(../../../assets/images/videoIcon.svg);
      }
    }
    i {
      display: block;
      width: 24px;
      height: 24px;
      font-size: 0;
      background-image: url(../../../assets/images/closeIcon.svg);
      cursor: pointer;
    }
  }

  .preview {
    margin-bottom: 15px;
    video {
      max-width: 288px;
      min-width: 288px;
      height: 200px;
      object-fit: cover;
    }
  }

  .details {
    div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
    }
  }
}
