.StyledButton {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 48px;
  padding-left: 44px;
  padding-right: 44px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.409091px;
  color: #fafafa;
  background-color: #2ecc71;
  border-radius: 26px;
  outline: none;
  box-shadow: none;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #1ab871;
  }
  &:active {
    background-color: #2ea571;
  }
  &:focus {
    background-color: #2ecc71;
    box-shadow: 0px 0px 0px 2px rgba(46, 204, 113, 0.5);
  }
  &:disabled {
    background-color: #82e0aa;
    cursor: auto;
  }

  &.outline {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    align-items: center;
    letter-spacing: 0.409091px;
    color: #2ecc71;
    background-color: transparent;
    border: 1px solid #2ecc71;
    border-radius: 26px;

    &:hover {
      &:not(:disabled) {
        color: #fafafa;
        background-color: #2ecc71;
      }
    }

    &:active {
      &:not(:disabled) {
        background-color: #2ea571;
      }
    }

    &:focus {
      color: #2ecc71;
      background-color: transparent;
      border: 1px solid #2ecc71;
      box-shadow: 0px 0px 0px 1px rgba(46, 204, 113, 0.5);
    }

    &:disabled {
      mix-blend-mode: normal;
      opacity: 0.6;
      cursor: auto;
    }
  }
}
