.overlay {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  //background-color: red;
}

.CreateHighlight {
  position: fixed;
  z-index: 4;
  display: none;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 272px;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0px -3px 14px -7px #000000;
  border-radius: 6px;

  &.show {
    display: flex;
  }

  &.top {
    &::after {
      top: 147px;
    }

    .threeDots {
      top: 201px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: -4.5px;
    display: bock;
    width: 9px;
    height: 9px;
    background-color: white;
    transform: rotate(45deg);
  }

  .threeDots {
    position: absolute;
    top: -15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 8px;
    box-sizing: border-box;
    background-color: #ffffff;
    border: 1px solid;
    box-sizing: border-box;
    border-radius: 4px;
    border-radius: 4px;

    .dotsContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 14px;
      height: 3px;
      div {
        box-sizing: border-box;
        min-width: 4px;
        max-width: 4px;
        min-height: 4px;
        max-height: 4px;
        border-radius: 50%;
      }
    }
  }

  textarea {
    resize: none;
    width: 252px;
    height: 70px;
    margin-bottom: 10px;
    padding: 7px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #232300;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    border-radius: 6px;

    &:focus {
      outline: none;
    }
  }

  ul {
    display: flex;
    justify-content: space-between;
    width: 245px;
    height: 14px;
    margin: 0;
    margin-bottom: 13px;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      display: block;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      cursor: pointer;

      &.active {
        &::after {
          content: '';
          position: absolute;
          left: -3px;
          top: -3px;
          display: block;
          box-sizing: border-box;
          width: 20px;
          height: 20px;
          border: 1px solid red;
          border-radius: 50%;
        }
      }

      &.green {
        background-color: #2ecc71;

        &::after {
          border-color: #2ecc71;
        }
      }

      &.orange {
        background-color: #ff9800;

        &::after {
          border-color: #ff9800;
        }
      }

      &.red {
        background-color: #f44336;

        &::after {
          border-color: #f44336;
        }
      }

      &.gray {
        background-color: #9e9e9e;

        &::after {
          border-color: #9e9e9e;
        }
      }

      &.purple {
        background-color: #9c27b0;

        &::after {
          border-color: #9c27b0;
        }
      }

      &.blue {
        background-color: #339de0;

        &::after {
          border-color: #339de0;
        }
      }

      &.yellow {
        background-color: #f3db05;

        &::after {
          border-color: #f3db05;
        }
      }

      &.pink {
        background-color: #cc2e8a;

        &::after {
          border-color: #cc2e8a;
        }
      }
    }
  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 115px;
      height: 24px;
      padding: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      letter-spacing: 2px;
      text-transform: uppercase;
      border: none;
      border-radius: 12px;
      cursor: pointer;

      &:first-child {
        color: #f44336;
        background-color: rgba(244, 67, 54, 0.15);
      }

      &:last-child {
        color: #2ecc71;
        background-color: rgba(46, 204, 113, 0.15);
      }
    }
  }
}
