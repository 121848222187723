.LeftMenu {
  display: flex;

  .undoRedo {
    display: flex;
    box-sizing: border-box;
    width: 104px;
    height: 48px;
    margin-right: 6px;
    padding-top: 4px;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    border-radius: 6px;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 10px;
      cursor: pointer;

      &:first-child {
        position: relative;
        margin-right: 13px;

        &::after {
          content: '';
          position: absolute;
          top: 8px;
          right: -11px;
          display: block;
          width: 1px;
          height: 18px;
          background-color: rgba(0, 0, 0, 0.15);
        }
      }

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #232300;
      }

      i {
        display: block;
        width: 24px;
        height: 24px;
        font-size: 0;

        &.redo {
          background-image: url(../../../../assets/images/redo.svg);
        }

        &.undo {
          background-image: url(../../../../assets/images/undo.svg);
        }
      }
    }
  }

  .videoOptions {
    display: flex;
    vertical-align: center;
    box-sizing: border-box;
    width: 236px;
    height: 48px;
    padding-top: 6px;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    border-radius: 6px;

    div:not(.overlay) {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px;
      cursor: pointer;

      &::after {
        content: '';
        position: absolute;
        top: 8px;
        right: 0;
        display: block;
        width: 1px;
        height: 18px;
        background-color: rgba(0, 0, 0, 0.15);
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      &.highlightsContainer {
        position: relative;
        .overlay {
          display: none;
        }

        &.disabled {
          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: block;
            background-color: rgba(255, 255, 255, 0.7);
            border: none;
          }
        }
        .highlightsMenu {
          position: absolute;
          top: -75px;
          z-index: 1;
          display: none;
          width: 136px;
          height: 60px;
          background-color: #ffffff;
          box-shadow: 0px 3px 14px -7px #000000;
          border-radius: 6px;

          &.show {
            display: flex;
          }

          &::after {
            content: '';
            position: absolute;
            left: calc(50% - 5px);
            top: 55px;
            display: block;
            width: 10px;
            height: 10px;
            background-color: white;
            transform: rotate(45deg);
          }

          ul {
            display: flex;
            flex-wrap: wrap;
            box-sizing: border-box;
            width: 136px;
            height: 60px;
            margin: 0;
            padding-top: 8px;
            padding-left: 16px;
            padding-right: 16px;
            padding-bottom: 8px;
            list-style: none;

            li {
              position: relative;
              width: 14px;
              height: 14px;
              margin-right: 15px;
              background-color: red;
              border-radius: 50%;
              margin-bottom: 16px;

              &:nth-child(4n + 4) {
                margin-right: 0;
              }

              &.active {
                span {
                  display: block;
                }

                &::after {
                  content: '';
                  position: absolute;
                  left: -3px;
                  top: -3px;
                  display: block;
                  box-sizing: border-box;
                  width: 20px;
                  height: 20px;
                  border: 1px solid;
                  border-color: inherit;
                  border-radius: 50%;
                }
              }
            }
          }
        }
      }

      i {
        display: block;
        width: 20px;
        height: 20px;
        font-size: 0;
        background-repeat: no-repeat;
        background-position: center;

        &.watermark {
          background-image: url(../../../../assets/images/watermarkIcon.svg);
        }

        &.addLogo {
          background-image: url(../../../../assets/images/addLogo.svg);
        }
      }

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #232300;
      }
    }
  }
}
