.folder.list {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  margin-bottom: 6px;
  padding-left: 64px;
  padding-right: 20px;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px -1px 4px -3px rgba(0, 0, 0, 0.5);
  cursor: pointer;

  h1 {
    width: 980px;
    margin: 0;
    padding: 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #232300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .createdAt,
  .updatedAt {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #7d7d7d;
  }

  .createdAt {
    position: relative;
    width: 292px;
    min-width: 80px;
  }

  .updatedAt {
    position: relative;
    width: 231px;
    min-width: 80px;
  }

  .folderMenu {
    margin-left: auto;
  }

  &::after {
    content: '';
    position: absolute;
    left: 20px;
    display: block;
    width: 30px;
    height: 30px;
    background-image: url(../../../assets/images/folderIcon.svg);
    background-size: contain;
  }
}

.folder.grid {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 271px;
  height: 60px;
  margin-right: 18px;
  margin-bottom: 12px;
  padding-left: 64px;
  padding-right: 20px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;

  h1 {
    margin: 0;
    padding: 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #232300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .createdAt,
  .updatedAt {
    display: none;
  }

  .folderMenu {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &::after {
    content: '';
    position: absolute;
    left: 20px;
    display: block;
    width: 24px;
    height: 24px;
    background-image: url(../../../assets/images/folderIcon.svg);
  }
}
