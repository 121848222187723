.Header {
  position: fixed;
  z-index: 4;
  top: 0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 64px;
  padding-left: 11px;
  padding-right: 36px;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  .logo {
    width: 152px;
    height: 27px;
    margin-right: 103px;
  }

  .search {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 271px;
    height: 42px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #f0f0f0;
    border-radius: 6px;
    border: none;
    outline: none;
    &::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 39px;
      color: #656565;
    }
  }

  .help {
    display: block;
    width: 28px;
    height: 28px;
    margin-left: auto;
    font-size: 0;
    background-image: url(../../assets/images/helpIcon.svg);
    cursor: pointer;
  }

  .userMenu {
    position: relative;
    width: 30px;
    height: 30px;
    margin-left: 42px;
    background-image: url(../../assets/images/avatar.png);
    background-size: 140% 140%;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      left: -16px;
      top: 15px;
      display: block;
      width: 10px;
      height: 5px;
      background-image: url(../../assets/images/userMenuArrow.svg);
    }

    .userMenuContent {
      position: absolute;
      top: 38px;
      left: -128px;
      box-sizing: border-box;
      display: none;
      width: 157px;
      padding-top: 8px;
      padding-bottom: 8px;
      background: #ffffff;
      box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.0242336),
        0px -2px 20.9961px rgba(0, 0, 0, 0.0357664),
        0px 2.39404px 6.2511px rgba(0, 0, 0, 0.06);
      border-radius: 6px;

      &.visible {
        display: block;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          position: relative;
          display: flex;
          align-items: center;
          height: 38px;
          padding-left: 44px;
          user-select: none;

          &::after {
            content: '';
            position: absolute;
            left: 13px;
            display: block;
            width: 18px;
            height: 18px;
            background-image: url(../../assets/images/logOutIcon.svg);
          }

          &:hover {
            background-color: #f3f3f3;
          }
        }
      }
    }
  }
  form {
    position: relative;
    .searchSuggestions {
      box-sizing: border-box;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 5px;

      position: absolute;
      top: 45px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background-color: white;
      box-shadow: 0px 2px 13px -5px #515151;
      border-radius: 6px;

      .searchSuggestion {
        padding: 5px;
        padding-right: 16px;
        padding-left: 16px;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        cursor: pointer;

        &:hover {
          background-color: #f0f0f0;
        }

        span {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        i {
          display: block;
          min-width: 16px;
          height: 16px;
          margin-right: 10px;
          font-size: 0;
          background-image: url(../../assets/images/folderIcon.svg);
          background-size: cover;
        }

        &.file {
          i {
            background-image: url(../../assets/images/videoIcon.svg);
          }
        }
      }
    }
  }
}
