.Comments {
  position: fixed;
  z-index: 11;
  top: 64px !important;
  right: -390px;
  bottom: 0;
  width: 380px;
  background: #ffffff;
  box-shadow: -5px 0px 20px -6px rgba(0, 0, 0, 0.3);
  transition: right 0.05s ease-out;
  overflow: auto;

  &.visible {
    right: 0;

    .header {
      right: 0;
    }
  }

  .header {
    position: fixed;
    z-index: 12;
    right: -390px;
    box-sizing: border-box;
    width: 380px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 68px;
    padding-left: 22px;
    padding-right: 22px;
    background: linear-gradient(45.54deg, #2ecc71 0%, #3eb152 127.36%);
    transition: right 0.05s ease-out;

    h1 {
      margin: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #ffffff;
    }

    i {
      display: block;
      width: 24px;
      height: 24px;
      font-size: 0;
      background-image: url(../../../../assets/images/whiteCloseIcon.svg);
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }
  }

  main.commentsMain {
    padding-top: 94px;
    padding-left: 14px;
    padding-right: 14px;
    .addCommentBox {
      position: relative;
      margin-bottom: 36px;
      padding: 8px;
      padding-top: 24px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      border-radius: 8px;

      .timeStamp {
        position: absolute;
        top: -14px;
        right: 21px;
        display: block;
        padding-left: 7px;
        padding-right: 7px;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #656565;
        background-color: white;
      }

      .sendComment {
        position: absolute;
        top: 36px;
        right: 16px;
        display: block;
        width: 24px;
        height: 24px;
        font-size: 0;
        background-image: url(../../../../assets/images/sendComment.svg);
        cursor: pointer;
      }

      textarea {
        box-sizing: border-box;
        width: 100%;
        height: 48px;
        padding-left: 16px;
        padding-top: 14px;
        padding-right: 32px;
        resize: none;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #232300;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;

        &::placeholder {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          color: rgba(35, 35, 0, 0.5);
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}
