.tooltipContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;

  .line {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .color {
    position: relative;
    top: 1px;
    display: block;
    width: 12px;
    height: 12px;
    margin-right: 10px;
    border-radius: 50%;
  }

  .menColor {
    left: -18px;
    margin-right: -8px;
    background-color: #2ecc71;
  }

  .womenColor {
    background-color: #9c27b0;
  }
}

.GenderChart {
  position: relative;
  .details {
    position: absolute;
    top: 5px;
    left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #232300;
    background-color: white;
    border-radius: 50%;
    user-select: none;
  }

  h1 {
    position: absolute;
    top: 61px;
    width: 60px;
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #656565;
  }
}
