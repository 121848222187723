.RightMenu {
  display: flex;
  align-items: center;
  width: 338px;
  height: 48px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 6px;

  &.sharedVideo {
    width: 99px;
    div.button {
      border: none;
    }
  }

  div.button {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 20px;
    padding-left: 9px;
    padding-right: 9px;
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    cursor: pointer;

    &:last-child {
      border: none;
    }

    span {
      position: relative;
      top: -8px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #232300;
      white-space: nowrap;
    }

    i {
      position: relative;
      top: -8px;
      display: block;
      width: 19px;
      min-height: 19px;
      font-size: 0;

      &.addComment {
        background-image: url(../../../../assets/images/addCommentButton.svg);
      }

      &.shareableLink {
        background-image: url(../../../../assets/images/shareableLink.svg);
      }

      &.share {
        background-image: url(../../../../assets/images/shareButton.svg);
      }

      &.presentation {
        background-image: url(../../../../assets/images/presentationButton.svg);
      }
    }
  }
}
